/**
 * @file Configuration overrides for the client.
 */

/** @type {import('@verosource/myhealth-web/src/config/networkConfig').NetworkConfig} */
const networkConfig = {
  keycloakBaseUrl: 'https://iam.myhealth.gnb-doh.vsfcloud.com/auth/',
  patientDataUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient/',
  patientSummaryUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient-summary/',
  patientSummaryManifestUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient-summary/manifest/',
  patientSummaryViewerUrl: 'https://psv-vrdp.gnb.ca/',
  patientSummaryRevokeUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient-summary/manifest/revoke-access',
  medicationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/medication/',
  messagingUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/messaging/',
  immunizationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/immunization/',
  observationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  diagnosticReportUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  imagingUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticImagingReport/',
  alertUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/alert/',
  redirectUri: 'https://myhealth-web.myhealth.gnb-doh.vsfcloud.com/',
  loginUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/authentication/login',
  authorizeUrl: 'https://id.gnb.ca/as/authorization.oauth2',
  clientId: 'MHNBPHRCLIENT',
  salesforcePortal: 'https://myhealth.gnb.ca/s/',
  loginProviderType: 'myhealth_broker_prod_new',
};

export default networkConfig;
